/* eslint-disable react/no-danger */
/* @flow */

import type { AwarditAgreement } from "shop-state/types";

import React, { useEffect, useRef, useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import { useTranslate } from "@awardit/react-use-translate";
import { Wrapper } from "@crossroads/ui-components";
import Breadcrumbs from "components/Breadcrumbs";

type Props = {
    agreement: null | AwarditAgreement,
  };

const toggleAccordionTitle = (e: MouseEvent) => {
  if (e.target instanceof HTMLElement) {
    const element = e.target.parentElement;

    if (element) {
      const isOpen = element.classList.contains("open");

      if (!isOpen) {
        element.classList.add("open");
      }
      else {
        element.classList.remove("open");
      }
    }
  }
};

const StripeAgreement = ({ agreement }: Props): React$Node => {
  const root = useRef<?HTMLDivElement>();
  const { routes: { termsView }, content: { checkoutview } } = useContext(StoreInfoContext);
  const t = useTranslate();

  useEffect(() => {
    const { current } = root;

    if (current) {
    // Bind accordions here
      const accordions = current.querySelectorAll(".agreement__accordion");

      for (const e of accordions) {
        if (e) {
          const title = e.querySelector(".agreement__accordionTitle");
          if (title) {
            title.addEventListener("click", toggleAccordionTitle);
          }
        }
      }
    }

    // Un-bind accordions
    return () => {
      if (current) {
        const accordions = current.querySelectorAll(".agreement__accordion");
        for (const e of accordions) {
          e.removeEventListener("click", toggleAccordionTitle);
        }
      }
    };
  }, [root]);

  return (
    agreement !== null && agreement !== undefined && (
      <Wrapper>
        <Breadcrumbs current={checkoutview.terms ?? ""} />

        <div className="header">
          <h2>{termsView && termsView.toggle !== undefined && termsView.toggle &&
          termsView.title !== undefined && termsView.title ?
            termsView.title :
            t("TERMSVIEW.FALLBACK_TITLE")}
          </h2>
        </div>

        <div
          ref={root}
          // key={}
          className="typography"
          dangerouslySetInnerHTML={{ __html: agreement.content }}
        />
      </Wrapper>
    )
  );
};

export default StripeAgreement;
