/* @flow */

import type { CmsPage } from "shop-state/types";

import React from "react";
import { useData } from "crustate/react";
import Wrapper from "components/Wrapper";
import { CmsData } from "data";
import { Link } from "react-router-dom";
import ArrowIcon from "icons/arrow.svg";
import LoadingView from "components/LoadingView";

import cn from "classnames";
import styles from "./styles.scss";

type Props = {
  isLoggedIn?: boolean,
};

const PrivacyNotice = ({ isLoggedIn = true }: Props) => {
  const privacyData = useData(CmsData);
  const privacyContent: CmsPage | any = privacyData.data !== null ? privacyData.data : null;
  const isNotLoggedIn = isLoggedIn !== null && isLoggedIn !== undefined && !isLoggedIn;

  if (privacyData.state !== "LOADED") {
    return <LoadingView />;
  }

  return (
    <Wrapper
      variant="pageWrapper"
      className={cn(
        { [styles.loginWrapper]: isNotLoggedIn },
        "awardit-pageWrapper",
        "awardit-privacyNotice"
      )}
    >
      {isNotLoggedIn &&
        <div className={styles.header}>
          <Link className={styles.link} to="/login">
            <ArrowIcon />
          </Link>
        </div>
      }
      {/* eslint-disable react/no-danger */}
      {privacyContent && privacyContent.content !== undefined ?
        (
          <div
            key={privacyContent.url}
            dangerouslySetInnerHTML={{ __html: privacyContent.content }}
            className="typography"
          />
        ) : null
      }
      {/* eslint-enable react/no-danger */}
    </Wrapper>
  );
};

export default PrivacyNotice;
